var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"primary pa-0 ma-0",attrs:{"id":"login"}},[_c('v-container',{staticClass:"primary fill-height ma-0 pa-0",attrs:{"fluid":""}},[_c('v-flex',{staticClass:"primary fill-height pa-0 ma-0",attrs:{"d-flex":"","child-flex":""}},[_c('v-row',{staticClass:"ma-0 pa-0",staticStyle:{"width":"100%","height":"100%"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"fill-height main-background-login pa-0 ma-0",attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? 12 : 7}},[_c('v-sheet',{staticClass:"fill-height d-flex flex-column justify-start",attrs:{"color":"transparent","height":"100%"}},[(
                _vm.$vuetify.breakpoint.mobile &&
                _vm.isAppError &&
                !_vm.isAccessTokenError
              )?_c('MarketplaceDialog',{attrs:{"show":_vm.showMarketplaceDialog},on:{"close":_vm.closeMarketplaceDialog}}):_vm._e(),(
                _vm.$vuetify.breakpoint.mobile &&
                !_vm.isAppError &&
                _vm.isAccessTokenError
              )?_c('ScopesDialog',{attrs:{"show":_vm.showScopesDialog},on:{"close":_vm.closeScopesDialog}}):(
                _vm.$vuetify.breakpoint.mobile &&
                !_vm.isAppError &&
                !_vm.isAccessTokenError
              )?_c('ErrorDialog',{attrs:{"show":_vm.hasAnError,"error-message":_vm.getErrorMessage},on:{"close":function($event){return _vm.setAuthError(false)}}}):_vm._e(),(!_vm.$vuetify.breakpoint.mobile)?_c('SloganDesktopLogin'):_vm._e(),(!_vm.$vuetify.breakpoint.mobile)?_c('v-spacer'):_c('v-card-actions',{class:`${
                _vm.$vuetify.breakpoint.xs ? 'justify-end' : 'justify-center'
              } px-0 pt-12 ma-0`},[_c('Logo',{staticClass:"ml-4",attrs:{"product":"conecta_suite","height":_vm.logoHeight,"full":"","white":"","no-margins":""}})],1),(_vm.$vuetify.breakpoint.sm)?_c('v-spacer'):_vm._e(),(!_vm.$vuetify.breakpoint.mobile)?_c('LoginFeaturesList'):_vm._e(),(_vm.$vuetify.breakpoint.xs)?_c('v-spacer'):_vm._e(),(_vm.$vuetify.breakpoint.mobile)?_c('LoginActions'):_vm._e(),(_vm.$vuetify.breakpoint.mobile)?_c('LoginFooter'):_vm._e()],1)],1),(!_vm.$vuetify.breakpoint.mobile)?_c('v-col',{staticStyle:{"position":"relative"},attrs:{"cols":"5"}},[(_vm.isAppError && !_vm.isAccessTokenError)?_c('MarketplaceDialog',{attrs:{"show":_vm.showMarketplaceDialog},on:{"close":_vm.closeMarketplaceDialog}}):_vm._e(),(!_vm.isAppError && _vm.isAccessTokenError)?_c('ScopesDialog',{attrs:{"show":_vm.showScopesDialog},on:{"close":_vm.closeScopesDialog}}):_vm._e(),_c('v-sheet',{staticClass:"fill-height d-flex flex-column justify-space-between",attrs:{"color":"grey lighten-3"}},[_c('v-card',{staticClass:"ma-auto",attrs:{"tile":"","flat":"","color":"transparent","max-width":"500"}},[_c('v-card',{staticClass:"d-flex justify-center pa-0 my-0 mx-auto",attrs:{"tile":"","flat":"","color":"transparent","max-width":"400","href":"https://conectasuite.com"}},[_c('Logo',{attrs:{"product":"conecta_suite","heigth":_vm.logoHeight,"full":"","no-margins":""}})],1),_c('v-subheader',{staticClass:"font-weight-medium d-flex justify-center text-body-1 mb-5"},[_vm._v(" "+_vm._s(_vm.$t("login.title"))+" ")]),(!_vm.isAppError && !_vm.isAccessTokenError)?_c('ErrorDialog',{attrs:{"show":_vm.hasAnError,"error-message":_vm.getErrorMessage},on:{"close":function($event){return _vm.setAuthError(false)}}}):_vm._e(),_c('LoginActions')],1),_c('LoginFooter')],1)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }