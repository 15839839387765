var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.$vuetify.breakpoint.md)?_c('div',{class:`${_vm.left ? '' : 'ml-auto'} ${
    _vm.centered ? 'd-flex justify-center mx-auto' : ''
  } font-weight-bold pa-7 ${_vm.colors ? '' : 'features-block'}  ${
    _vm.$vuetify.breakpoint.mobile ? 'mx-auto px-3' : 'mr-0'
  } ${_vm.customBlockClass}`,style:(`${_vm.centered ? 'max-width:300px;' : ''}`)},[_c('div',{class:`d-flex flex-column ${
      _vm.centered ? 'align-center justify-center' : 'align-end'
    } mx-auto`},_vm._l((_vm.mainFeatures),function({ text, background_class },index){return _c('span',{key:index,class:`features ${
        _vm.colors ? background_class : ''
      } ${_vm.customFeatureClass}`},[_vm._v(" "+_vm._s(_vm.$t(text))+" ")])}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }