<template>
  <v-app id="login" class="primary pa-0 ma-0">
    <v-container fluid class="primary fill-height ma-0 pa-0">
      <v-flex d-flex child-flex class="primary fill-height pa-0 ma-0">
        <v-row no-gutters class="ma-0 pa-0" style="width: 100%; height: 100%">
          <v-col
            :cols="$vuetify.breakpoint.mobile ? 12 : 7"
            class="fill-height main-background-login pa-0 ma-0"
          >
            <v-sheet
              color="transparent"
              class="fill-height d-flex flex-column justify-start"
              height="100%"
            >
              <!-- ERROR MESSAGE -->
              <MarketplaceDialog
                v-if="
                  $vuetify.breakpoint.mobile &&
                  isAppError &&
                  !isAccessTokenError
                "
                :show="showMarketplaceDialog"
                @close="closeMarketplaceDialog"
              />

              <ScopesDialog
                v-if="
                  $vuetify.breakpoint.mobile &&
                  !isAppError &&
                  isAccessTokenError
                "
                :show="showScopesDialog"
                @close="closeScopesDialog"
              />

              <ErrorDialog
                v-else-if="
                  $vuetify.breakpoint.mobile &&
                  !isAppError &&
                  !isAccessTokenError
                "
                :show="hasAnError"
                :error-message="getErrorMessage"
                @close="setAuthError(false)"
              />

              <!-- LOGIN SLOGAN -->
              <SloganDesktopLogin v-if="!$vuetify.breakpoint.mobile" />

              <v-spacer v-if="!$vuetify.breakpoint.mobile" />

              <!-- LOGOTIPO -->
              <v-card-actions
                v-else
                :class="`${
                  $vuetify.breakpoint.xs ? 'justify-end' : 'justify-center'
                } px-0 pt-12 ma-0`"
              >
                <Logo
                  product="conecta_suite"
                  :height="logoHeight"
                  full
                  white
                  class="ml-4"
                  no-margins
                />
              </v-card-actions>

              <v-spacer v-if="$vuetify.breakpoint.sm" />

              <!-- FEATURED LIST -->
              <LoginFeaturesList v-if="!$vuetify.breakpoint.mobile" />

              <v-spacer v-if="$vuetify.breakpoint.xs" />

              <LoginActions v-if="$vuetify.breakpoint.mobile" />

              <LoginFooter v-if="$vuetify.breakpoint.mobile" />
            </v-sheet>
          </v-col>
          <v-col
            v-if="!$vuetify.breakpoint.mobile"
            cols="5"
            style="position: relative"
          >
            <MarketplaceDialog
              v-if="isAppError && !isAccessTokenError"
              :show="showMarketplaceDialog"
              @close="closeMarketplaceDialog"
            />

            <ScopesDialog
              v-if="!isAppError && isAccessTokenError"
              :show="showScopesDialog"
              @close="closeScopesDialog"
            />

            <v-sheet
              color="grey lighten-3"
              class="fill-height d-flex flex-column justify-space-between"
            >
              <v-card
                tile
                flat
                color="transparent"
                class="ma-auto"
                max-width="500"
              >
                <v-card
                  tile
                  flat
                  color="transparent"
                  class="d-flex justify-center pa-0 my-0 mx-auto"
                  max-width="400"
                  href="https://conectasuite.com"
                >
                  <Logo
                    product="conecta_suite"
                    :heigth="logoHeight"
                    full
                    no-margins
                  />
                </v-card>

                <v-subheader
                  class="font-weight-medium d-flex justify-center text-body-1 mb-5"
                >
                  {{ $t("login.title") }}
                </v-subheader>

                <ErrorDialog
                  v-if="!isAppError && !isAccessTokenError"
                  :show="hasAnError"
                  :error-message="getErrorMessage"
                  @close="setAuthError(false)"
                />

                <LoginActions />
              </v-card>

              <LoginFooter />
            </v-sheet>
          </v-col>
        </v-row>
      </v-flex>
    </v-container>
  </v-app>
</template>

<script>
import ErrorDialog from "@/components/general/ErrorDialog.vue";
import LoginActions from "@/components/login/LoginActions";
import LoginFeaturesList from "@/components/login/LoginFeaturesList";
import LoginFooter from "@/components/login/LoginFooter";
import SloganDesktopLogin from "@/components/login/SloganDesktopLogin";
import MarketplaceDialog from "@/components/login/MarketplaceDialog.vue";
import ScopesDialog from "@/components/login/ScopesDialog.vue";

import { errorMessages } from "@/helpers/variables/snackbarMessages";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Login",

  components: {
    ErrorDialog,
    LoginActions,
    LoginFeaturesList,
    LoginFooter,
    SloganDesktopLogin,
    MarketplaceDialog,
    ScopesDialog,
  },

  data: () => ({
    showMarketplaceDialog: true,
    showScopesDialog: true,
  }),

  computed: {
    ...mapGetters(["authError"]),

    hasAnError() {
      return !!this.authError;
    },

    isAppError() {
      return this.authError === "MARKETPLACE_APP_NOT_INSTALLED";
    },

    isAccessTokenError() {
      return this.authError === "INVALID_GOOGLE_ACCESS_TOKEN";
    },

    getErrorMessage() {
      const unknownError =
        "Erro deconhecido. Entre em contato <a href='mailto:suporte@conectasuite.com'>suporte@conectasuite.com</a>";

      return this.authError
        ? errorMessages[this.authError] || unknownError
        : unknownError;
    },

    logoHeight() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 60;
      } else if (this.$vuetify.breakpoint.md) {
        return 70;
      } else if (this.$vuetify.breakpoint.sm) {
        return 60;
      } else {
        return 40;
      }
    },
  },

  methods: {
    ...mapMutations(["setAuthError"]),

    closeMarketplaceDialog() {
      this.showMarketplaceDialog = false;
    },
    closeScopesDialog() {
      this.showScopesDialog = false;
    },
  },
};
</script>

<style scoped>
.main-background-login {
  background-image: url("../../assets/images/login.jpg");
  background-position: 20% 95%;
  background-size: cover;
}

@media (max-width: 320px) {
  .main-background-login {
    background-position: center right;
  }
}

@media (max-width: 600px) {
  .main-background-login {
    background-position: 40% 15%;
  }
}

@media (min-width: 601px) and (max-width: 1024) {
  .main-background-login {
    background-position: 90% 15%;
  }
}
</style>
