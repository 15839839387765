<template>
  <v-btn
    text
    dark
    :color="$vuetify.breakpoint.mobile ? 'white' : 'primary lighten-1'"
    class="font-weight-bold text-none text-body-2 pt-0 px-4 mt-0 text-decoration-underline d-flex text-wrap break-word"
    @click="redirectToMarketplace"
    target="_blank"
  >
    {{ $t("action.installApp") }}
  </v-btn>
</template>
<script>
import { openInNewTab } from "@/helpers/services/utils";

export default {
  name: "InstallAppButton",

  methods: {
    redirectToMarketplace() {
      const link = `https://workspace.google.com/marketplace/app/conecta_suite/${process.env.VUE_APP_MARKETPLACE_APP_ID}`;
      openInNewTab(link);
    },
  },
};
</script>
