<template>
  <v-btn
    color="white"
    height="48"
    :width="$vuetify.breakpoint.xs ? 280 : 340"
    :max-width="$vuetify.breakpoint.xs ? 280 : 340"
    class="font-weight-bold text-none d-flex align-center justify-center text-body-1"
    @click="login"
  >
    <v-row class="ma-0 pa-0" no-gutters align="center" justify="center">
      <v-col cols="2" class="d-flex justify-end">
        <v-img
          :src="logo_google"
          :width="$vuetify.breakpoint.xs ? 20 : 25"
          :height="$vuetify.breakpoint.xs ? 20 : 25"
          contain
          class="login-button-logo"
        />
      </v-col>
      <v-col> {{ $t("action.loginGoogle") }} </v-col>
    </v-row>
  </v-btn>
</template>
<script>
export default {
  name: "LoginWithGoogle",

  data() {
    return {
      logo_google: require("@/assets/images/logo_google.png"),
    };
  },

  methods: {
    login() {
      window.location.href = process.env.VUE_APP_API_BASE_URL + "/auth";
    },
  },
};
</script>
<style>
.login-text-spotlight {
  color: var(--v-accent-base);
  background-color: rgba(78, 58, 100, 0.9);
  padding: 0px;
  opacity: 0.8;
}

.login-slogan-text {
  font-size: 38px;
}

.login-button-logo {
  mix-blend-mode: multiply;
}
</style>
